body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'arvo';
  src: local('arvo'), url(./fonts/Arvo/Arvo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'merriweather';
  src: local('merriweather'), url(./fonts/Merriweather/Merriweather-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'pt-serif';
  src: local('pt-serif'), url(./fonts/PT-Serif/PTSerif-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'lora';
  src: local('lora'), url(./fonts/Lora/static/Lora-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'nanum';
  src: local('nanum'), url(./fonts/Nanum-Myeongjo/NanumMyeongjo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'playfair';
  src: local('playfair'), url(./fonts/Playfair/static/PlayfairDisplay-Regular.ttf) format('truetype');
}

h1 {
  font-size: 50px;
  font-style: normal;
  font-weight: normal;
}

h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: normal;
}